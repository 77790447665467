class ActiveSection {
    constructor() {
        this._stageSection();

    }

    _stageSection() {
        if ($("body").hasClass("archive")) {
            $(".menu-item-has-children.vinos").addClass("current-menu-item");
        }
        if ($("body").hasClass("home")) {
            $(".menu-item-has-children.inicio").addClass("current-menu-item");
        }
        if ($("body").hasClass("single-vinos")) {
            $(".menu-item-has-children.vinos").addClass("current-menu-item");
        }
        if ($("body").hasClass("page-template-nosotros")) {
            $(".menu-item-has-children.nosotros").addClass("current-menu-item");
        }
    }
}